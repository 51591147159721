.outbound_list_item {
    width: 100%;
    height: 11%;
    background-color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5%;
    margin-top: 2vh;
}

.order_row_section {
    height: 100%;
    width: 22.5%;
    background-color: #f3f3f3;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    font-weight: 200;
    border-radius: 8px;
}

.small_order_row_section {
    width: 10%;
}

.order_row_icon {
    cursor: pointer;
}

.interact_order_section {
    cursor: pointer;
}

.order_row_icon {
    height: 50%;
    width: auto;
    cursor: pointer;
}

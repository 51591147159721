.tracking_wrapper {
    width: 70%;
    height: 70vh;
    background-color: white;
    border-radius: 12px;
    margin-top: 150vh;
    transition: 0.3s ease-in-out;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1.5%;
}

/* map */
.map_wrapper {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 8px;
    position: relative;
    z-index: 200;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tracking_desc {
    font-size: 2em;
    width: 85%;
}

.lower_tracking_desc {
    font-size: 1.4em;
}

/* map internal desc */
.selected_shop {
    width: 85%;
    font-size: 1.4em;
    font-weight: lighter;
}



.login_wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.25% 1.25% 1.25% 0;
}

.login_section {
    height: 100%;
    width: 57.5%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
}

.login_section:last-child {
    width: 42.5%;
    background-color: #505DF7;
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;
    font-size: 3.5em;
    font-weight: bold;
    color: white;
    letter-spacing: 2px;
    padding: 4%;
}

.login_field_wrapper {
    width: 72.5%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.login_input {
    width: 100%;
    height: 7.5vh;
    border-radius: 6px;
    border-color: black;
    border-width: 1px;
    padding: 1% 2.5% 1% 2.5%;
    font-size: 14px;
    font-weight: 300;
    margin: 1.5% 0 1.5% 0;
}

.login_input_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    padding: 0%;
}

.short_login_input {
    width: 47.5%;
}

.login_divider {
    width: 100%;
    height: 1px;
    background-color: black;
    margin: 2.5% 0 2.5% 0;
}

.login_btn {
    height: 6.5vh;
    padding: 0 3vw 0 3vw;
    background-color: #505DF7;
    color: white;
    border-radius: 6px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    margin: 5% 3% 0 0;
    cursor: pointer;
    border: 1px solid #505DF7;
    white-space: nowrap;
}

.forgot_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #505DF7;
}

.signup_txt_btn {
    font-size: 14px;
    font-weight: 300;
    margin-top: 3%;
    width: 100%;
}

.signup_txt_link {
    color: black;
}

/* outer login */
.outer_login_wrapper {
    width: 72.5%;
    height: 25%;
    display: flex;
}

.outer_login_wrapper:first-child {
    font-size: 3.5em;
    font-weight: bold;
    letter-spacing: 2px;
    align-items: center;
}

.outer_login_wrapper:last-child {
    align-items: flex-end;
}

.login_legal {
    font-size: 0.7em;
    font-weight: 200;
}
